import getArticleField from './news/getArticleField';

export type ConnectionField = 'auFashionunitedComNewsArticlesConnection'
| 'fashionunitedBeFrNewsArticlesConnection'
| 'fashionunitedBeNewsArticlesConnection'
| 'fashionunitedCaFrNewsArticlesConnection'
| 'fashionunitedCaNewsArticlesConnection'
| 'fashionunitedChNewsArticlesConnection'
| 'fashionunitedClNewsArticlesConnection'
| 'fashionunitedCnNewsArticlesConnection'
| 'fashionunitedComArNewsArticlesConnection'
| 'fashionunitedComNewsArticlesConnection'
| 'fashionunitedComPeNewsArticlesConnection'
| 'fashionunitedCoNewsArticlesConnection'
| 'fashionunitedDeNewsArticlesConnection'
| 'fashionunitedEsNewsArticlesConnection'
| 'fashionunitedFrNewsArticlesConnection'
| 'fashionunitedInfoNewsArticlesConnection'
| 'fashionunitedInNewsArticlesConnection'
| 'fashionunitedItNewsArticlesConnection'
| 'fashionunitedMxNewsArticlesConnection'
| 'fashionunitedNlNewsArticlesConnection'
| 'fashionunitedNzNewsArticlesConnection'
| 'fashionunitedRuNewsArticlesConnection'
| 'fashionunitedUkNewsArticlesConnection'
| 'fashionweekwebComNewsArticlesConnection'
| 'internationalfashionjobsComNewsArticlesConnection'
| 'newsArticlesConnection';

const getConnectionField = (
  prefix: string,
  hasLocalNews: boolean,
): ConnectionField => `${
  getArticleField(prefix, hasLocalNews)}sConnection` as ConnectionField;

export default getConnectionField;
